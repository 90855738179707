import { ColumnDef } from '@tanstack/react-table';
import {
  IconButton,
  SortButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { IconFlag } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { priorityMap } from '~/utils/mapPriority';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import { Information, Priority } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { History } from '~/main/routes';
import { iOnCallRequestRecords } from '~/domain/usecases/onCallRequest/remote';
import { makeRemoteAssignOnCallRequest } from '~/main/factories/usecases/onCallRequest/AssignOnCallRequestFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export const columns_waiting: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'requester',
    header: ({ column }) => (
      <SortButton column={column}>Profissional solicitante</SortButton>
    ),
    cell: ({ row }) => {
      const data = row.getValue(
        'requester',
      ) as iOnCallRequest['records'][0]['requester'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.specialtyName}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'healthUnit',
    header: ({ column }) => {
      return <SortButton column={column}>Unidade de saúde</SortButton>;
    },
    cell: ({ row }) => {
      const healthUnit = row.getValue(
        'healthUnit',
      ) as iOnCallRequest['records'][0]['healthUnit'];

      return <Typography variant="b4_14regular">{healthUnit?.name}</Typography>;
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <SortButton column={column}>Prioridade</SortButton>,
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as iOnCallRequest['records'][0]['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    accessorKey: 'created',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const created = row.getValue(
        'created',
      ) as iOnCallRequest['records'][0]['created'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(created).date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(created).hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      // const professional_id = getProfessionalInfo();

      // const handleGoToDetails = (id: number, edit = false) => {
      //   if (edit) {
      //     history.push(`/diagnosis/${id}`, {
      //       edit,
      //     });
      //     return;
      //   }

      //   history.push(`/diagnosis/${id}`);
      // };

      const store: iStore = storeDev.getState();
      const professional = getProfessionalInfo();

      const isMyRequest =
        value?.requester?.id === professional?.id ||
        value?.specialist?.id === professional?.id;

      const { role } = store.auth.selectUser;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {!isMyRequest && value.permissions?.sign && (
            <Tooltip content="Aceitar solicitação" arrow>
              <IconButton
                icon="video_call"
                variant="primary"
                size="sm"
                onClick={() => {
                  if (!professional) return;

                  makeRemoteAcceptOnCallSpecialist()
                    .accept({
                      onCallId: value?.onCall?.id,
                      requestId: value?.id,
                      specialistId: professional.id,
                    })
                    .catch(() => {
                      AlertMessage({
                        message: 'Erro ao aceitar solicitação',
                        type: 'danger',
                      });
                    });
                }}
              />
            </Tooltip>
          )}
          {value?.permissions?.sign && (
            <Tooltip content="Parecer" arrow>
              <IconButton
                icon="signature"
                variant="primary"
                size="sm"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'twoButtonModal',
                    actionCancel: () => {
                      closeModal();
                    },
                    actionOk: () => {
                      if (professional)
                        makeRemoteAssignOnCallRequest()
                          .assign({
                            onCallId: value?.onCall?.id,
                            specialistId: professional?.id,
                            requestId: value?.id,
                          })
                          .then(() =>
                            History.getHistory().push(
                              `/duty/${value?.onCall?.id}/opinion/${value?.id}`,
                            ),
                          )
                          .catch(() => {
                            AlertMessage({
                              message: 'Erro ao vincular parecer',
                              type: 'danger',
                            });
                          });

                      closeModal();
                    },
                    data: {
                      variant: 'INFO',
                      message:
                        'Caso comece a escrever o parecer médico, essa solicitação agora ficará sob sua responsabilidade. Tem certeza de que quer continuar?',
                      title: 'Iniciar parecer médico',
                      textSecondaryButton: 'Cancelar',
                      textPrimaryButton: 'Sim, quero continuar',
                    },
                  });
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];
