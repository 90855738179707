import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 886px;
  width: 100%;
  border-radius: 8px;
`;

export const CheckContent = styled.div`
  border: 1px solid #dedede;
  border-radius: 8px;
  background-color: #ffffff;
`;

interface iOption {
  checked: boolean;
}
export const Option = styled.div<iOption>`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 18px;

  ${({ checked }) =>
    checked &&
    css`
      border-bottom: 1px solid #dedede;
    `}

  input[type='radio'] {
    transform: scale(1.3);
  }

  span {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #222529;
    font-weight: 500;
  }
`;

export const CheckedForms = styled.div`
  display: flex;
  align-items: baseline;
  gap: 24px;
  padding: 24px;
`;

export const ProfessionalCheckedForms = styled.div`
  padding: 24px;
`;

export const InlineForms = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  margin-bottom: 24px;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 2px;
    font-family: 'Roboto', sans-serif;
  }

  #specialty {
    width: 598px;
  }

  #profession_professional {
    width: 200px;
  }

  #specialty_professional {
    width: 614px;
  }
`;

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuList: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  option: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuPortal: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
});

export const Required = styled.div`
  color: #dd0404;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

interface iSelectLabel {
  required?: boolean;
}
export const SelectLabel = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;
