import React, { useEffect, useState } from 'react';
import { Icon, Typography, Button } from '@wisecare-tech/design-system-web';
import {
  BoxIcon,
  Container,
  Content,
  Header,
  Text,
  Professional,
  Footer,
  ContainerModal,
  Priority,
} from './styles/StyledModalRequestSuccess';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { IconFlag } from '~/presentation/base/icons';
import { priorityMap } from '~/utils/mapPriority';
import { useSelector } from 'react-redux';
import { History } from '~/main/routes';
import { ListOnCall } from '~/domain/usecases/duty/remote';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';

interface propsModalRequestSuccess {
  message: iMessage;
}

const ModalRequestSuccess: React.FC<propsModalRequestSuccess> = ({
  message,
}) => {
  const [onCall, setOnCall] = useState<ListOnCall.Model['records'][0]>();

  const { active, actionCancel, actionOk, data } = message;

  const { results: specialty } = useSelector(
    (store: iStore) => store.specialty,
  );

  const { results: professionals } = useSelector(
    (store: iStore) => store.professional,
  );

  const msgName = MessageOptions.requestSuccess;

  const specialtySelected = specialty?.find(
    item => item.specialty?.id === Number(data?.specialty),
  );

  const professionalSelected = professionals?.find(
    item => item.professional?.id === Number(data?.professionalId),
  );

  useEffect(() => {
    makeRemoteListOnCall()
      .get({})
      .then(res => {
        const filterOnCall = res?.records?.find(item => {
          return item?.id === Number(data?.specialty);
        });
        setOnCall(filterOnCall);
      })
      .catch(console.error);
  }, [data?.specialty]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Header>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F4FDE9',
                    borderRadius: '9999px',
                    aspectRatio: '1',
                    width: '40px',
                  }}
                >
                  <Icon name="check_circle" color="green-7" size="h5" />
                </div>
                <BoxIcon
                  onClick={() => {
                    actionOk?.();
                  }}
                >
                  <Icon name="close" color="black-1" size="large" />
                </BoxIcon>
              </Header>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Sua solicitação foi enviada!
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Sua solicitação de interconsulta com um profissional do
                  plantão foi enviada com sucesso.
                  <br /> <br /> Lembre-se: Após o especialista entrar na
                  consulta, você tem um período de 5 minutos para entrar no
                  atendimento, após isso, sua solicitação poderá ser cancelada.
                </Typography>
              </Text>
            </Content>
            <Professional>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Profissão:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {professionalSelected?.profession?.name}
                </Typography>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Especialidade:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {onCall?.descr}
                </Typography>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Prioridade:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  <Priority priority={data?.priority}>
                    <IconFlag />
                    {priorityMap(data?.priority)}
                  </Priority>
                </Typography>
              </div>
            </Professional>
            <Footer>
              <Button size="md" onClick={actionOk} variant="primary">
                Concluir
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalRequestSuccess;
