/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import _ from 'lodash';
import {
  Content,
  ContentHeader,
  HeaderTitle,
  ContentBody,
  HeaderCloseIcon,
} from '../styles/sidebarStyles';
import {
  Header,
  PatientInfo,
  ContentHeaderTitle,
  Expand,
  Icon,
  Body,
  Footer,
  FooterText,
  Pagination as PaginationContainer,
} from '../styles/notesStyles';

import { translator } from '../../i18n';
import { Notes as NotesComponent } from '~/presentation/components/notes';
import { ConnectComponent } from './mapper/MapperNotes';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { iListClinDocs } from './interface';

export interface ownProps {
  data?: iListClinDocs[];
}

export interface externalProps {
  handleClose: () => void;
  appointment: GetAppointmentById.Model;
}

const Notes: React.FC<ownProps & externalProps> = ({
  data,
  handleClose,
  appointment,
}) => {
  const [displayedLabels, setDisplayedLabels] = useState<number[]>([]);
  const [activeLabel, setActiveLabel] = useState<number>(
    appointment?.appointment?.id,
  );
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFirst = () => {
    if (!data?.length) return;

    const ids = data.slice(0, expanded ? 5 : 3).map(item => item.id);
    setDisplayedLabels(ids);
  };

  const handleLast = () => {
    if (!data?.length) return;

    const ids = _.takeRight(data, expanded ? 5 : 3).map(item => item.id);
    setDisplayedLabels(ids);
  };

  const handlePrevious = () => {
    if (!data?.length) return;

    const ids = data
      .map((item, index) => {
        if (displayedLabels.includes(item.id) && index - 1 >= 0) {
          return data[index - 1].id;
        }
      })
      .filter(Boolean);

    if (displayedLabels.length <= ids.length)
      setDisplayedLabels(ids as number[]);
  };

  const handleNext = () => {
    if (!data?.length) return;

    const ids = data
      .map((item, index) => {
        if (displayedLabels.includes(item.id) && index + 1 < data.length) {
          return data[index + 1].id;
        }
      })
      .filter(Boolean);

    if (displayedLabels.length <= ids.length)
      setDisplayedLabels(ids as number[]);
  };

  const renderPaginationItems = useMemo(() => {
    return data?.map(item => {
      if (!displayedLabels.includes(item.id)) return null;
      return (
        <Pagination.Item
          key={item.id}
          active={item.id === activeLabel}
          onClick={() => setActiveLabel(item.id)}
        >
          {item.label}
        </Pagination.Item>
      );
    });
  }, [activeLabel, data, displayedLabels]);

  useEffect(() => {
    handleLast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, expanded]);

  return (
    <Content expanded={expanded} overflow>
      <ContentHeader>
        <HeaderTitle>{translator('Anotações')}</HeaderTitle>
        <HeaderCloseIcon onClick={handleClose} />
      </ContentHeader>
      <ContentBody padding>
        <Header>
          <PatientInfo>
            <ContentHeaderTitle>
              {`${translator('Paciente')}: `}
              <ContentHeaderTitle bold>
                {appointment?.appointment?.info ? (
                  <ContentHeaderTitle bold>
                    {appointment?.appointment?.info?.name ?? 'Não informado'}
                  </ContentHeaderTitle>
                ) : (
                  <ContentHeaderTitle bold>
                    {appointment?.consultant?.firstName
                      ? `${appointment?.consultant?.firstName} ${appointment?.consultant?.lastName}`
                      : '-'}
                  </ContentHeaderTitle>
                )}
              </ContentHeaderTitle>
            </ContentHeaderTitle>
          </PatientInfo>

          <Expand onClick={() => setExpanded(prevState => !prevState)}>
            <ContentHeaderTitle clickable>
              {expanded
                ? translator('Diminuir nota')
                : translator('Expandir nota')}
            </ContentHeaderTitle>
            <Icon />
          </Expand>
        </Header>
        <Body>
          <NotesComponent
            sidebar
            appointmentId={activeLabel}
            handleSavingLoading={setLoading}
          />
        </Body>
        <Footer>
          <FooterText>
            {loading
              ? translator('Salvando...')
              : translator('Salvamento automático')}
          </FooterText>
        </Footer>
        <PaginationContainer>
          <Pagination>
            <Pagination.First onClick={handleFirst} />
            <Pagination.Prev onClick={handlePrevious} />
            {renderPaginationItems}
            <Pagination.Next onClick={handleNext} />
            <Pagination.Last onClick={handleLast} />
          </Pagination>
        </PaginationContainer>
      </ContentBody>
    </Content>
  );
};

export default ConnectComponent(Notes);
