import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature, SignDoc } from '~/presentation/components/signature';
import { Button } from '~/presentation/components/UI';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { Prescription } from '~/presentation/components/prescription';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import GeneralData, {
  ownProps as GeneralDataProps,
} from './phases/GeneralData';
import { iCreateMedicalCertificate } from './interface';
import { ConnectComponent } from './mapper/MapperMedicalCertificateSideBar';
import {
  Body,
  Container,
  ContainerButton,
  Phases,
  TitleDoc,
} from './styles/StyledMedicalCertificateSideBar';
import PacientData from './phases/PacientData';
import PrescriptionData from './phases/PrescriptionData';
import { makeRemotePresignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/PresignClinicalDocsDetachedFactory';

export interface externalProps {
  medicalCertificate?: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
  goBackToListDocument: () => void;
}

interface iStateParams {
  appointmentId: string;
  professional: number;
  consultant: number;
}

export type ownProps = GeneralDataProps;

const MedicalCertificateSideBar: React.FC<ownProps & externalProps> = props => {
  const [phases, setPhases] = useState(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stateParams = useLocation<iStateParams>().state;
  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const professional = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const [certificate, setCertificate] = useState<iCreateMedicalCertificate>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient: {
      id:
        stateParams && stateParams?.consultant
          ? Number(stateParams?.consultant)
          : undefined,
      fullname: props.state?.patient?.fullname ?? '',
      gender: props.state?.patient?.gender ?? '',
      age: props.state?.patient?.age ?? -1,
    },
    specialty:
      role === 'PRO' ? professional?.professions.specialties[0].id : -1,
    content: {
      periodo: '',
      observacoes: '',
    },
    orgUnitName,
    org: orgId,
    type: 'MEDICALCERTIFICATE',
    situation: props.state?.situation ?? 'REGISTERED',
  });

  const { goBackToListDocument } = props;

  useEffect(() => {
    setCertificate(prevState => ({
      ...prevState,
      patient: {
        id: stateParams.consultant ?? certificate.patient?.id,
      },
      professional: stateParams.professional ?? certificate.professional,
    }));
  }, [stateParams]);

  const CreateMedicalCertificate = () => {
    // SE TIVER VINCULADO A UM ATENDIMENTO
    if (stateParams && stateParams.appointmentId !== undefined) {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        appointment: stateParams.appointmentId
          ? Number(stateParams.appointmentId)
          : -1,
        type: 'MEDICALCERTIFICATE',
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        signatureSource: certificate.signatureSource ?? undefined,
        certificate:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificate,
        certificateId:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificateId,
        sidebar: true,
      };

      try {
        if (certificate.signatureSource === 'VIDAAS') {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname ?? '',
                    gender: certificate?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: certificate.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .then(res => {
                storeDev.dispatch({
                  type: MessageOptions.clinicalDocsCreateSuccess,
                });
                makeRemotePresignClinicalDocs()
                  .presign({ docId: Number(res?.id) })
                  .then(response => {
                    const url = response?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(res?.id));
                    window.open(url, '_blank');
                    // window.location.href = url;
                  })
                  .catch(e => {
                    console.log('##error: ', e);
                  });
                console.log('##res: ', res);

                goBackToListDocument?.();
              })
              .catch(e => {
                console.log('##error: ', e);
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemotePresignClinicalDocs()
                .presign({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  window.open(url, '_blank');
                  // window.location.href = url;
                })
                .catch(e => console.log(e));

              goBackToListDocument?.();
            },
          });
        } else {
          // Paciente não cadastrado cai aqui, e não tá assinando
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname ?? '',
                    gender: certificate?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource:
                    certificate.signatureSource as CreateForeignClinicalDocs.Params['body']['signatureSource'],
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .then(res => {
                AlertMessage({
                  message: 'Documento criado com sucesso',
                  type: 'success',
                });
                console.log('###res: ', res);
                goBackToListDocument?.();
              })
              .catch(e => {
                console.log('###error: ', e);
              });

            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            goBackToListDocument,
          });
        }
      } catch (e) {
        console.log('create clinical docs error: ', e);
      }
      // SE NÃO TIVER VINCULADO A UM ATENDIMENTO
    } else {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        type: 'MEDICALCERTIFICATE',
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          certificate.situation === 'NOT-REGISTERED'
            ? {
                fullname: certificate?.patient?.fullname ?? '',
                gender: certificate?.patient?.gender ?? '',
                age: certificate?.patient?.age ?? -1,
              }
            : certificate?.patient?.id,
        professional: certificate.professional,
        certificate:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificate,
        certificateId:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificateId,
        specialty: certificate.specialty,
        signatureSource: certificate?.signatureSource ?? undefined,
        sidebar: true,
      };

      try {
        if (certificate?.signatureSource === 'VIDAAS') {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname ?? '',
                    gender: certificate?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: certificate.signatureSource ?? undefined,
                  // appointment: stateParams.appointmentId
                  //   ? Number(stateParams.appointmentId)
                  //   : -1,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .then(res => {
                storeDev.dispatch({
                  type: MessageOptions.clinicalDocsCreateSuccess,
                });
                makeRemotePresignClinicalDocs()
                  .presign({ docId: Number(res?.id) })
                  .then(response => {
                    const url = response?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(res?.id));
                    window.open(url, '_blank');
                    // window.location.href = url;
                  })
                  .catch(e => {
                    console.log('##error: ', e);
                  });
                goBackToListDocument?.();
                console.log('##res: ', res);
              })
              .catch(e => {
                console.log('##error: ', e);
              });
            return;
          }

          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemotePresignClinicalDocs()
                .presign({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  window.open(url, '_blank');
                  // window.location.href = url;
                })
                .catch(e => console.log(e));

              goBackToListDocument?.();
            },
          });
        } else {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname ?? '',
                    gender: certificate.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: undefined,
                  // appointment: stateParams.appointmentId
                  //   ? Number(stateParams.appointmentId)
                  //   : -1,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .then(res => {
                storeDev.dispatch({
                  type: MessageOptions.clinicalDocsCreateSuccess,
                });
                AlertMessage({
                  message: 'Documento criado com sucesso',
                  type: 'success',
                });
                goBackToListDocument?.();
              })
              .catch(err => {
                console.error(err);
              });

            return;
          }

          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            goBackToListDocument,
          });
          storeDev.dispatch({ type: MessageOptions.clinicalDocsCreateSuccess });
        }
      } catch (e) {
        console.log('create clinical docs error: ', e);
      }
    }
  };

  function next(dados: iCreateMedicalCertificate) {
    setCertificate(prevState => ({ ...prevState, ...dados }));
    handlePhases(1);
  }

  function back(dados: iCreateMedicalCertificate) {
    setCertificate(prevState => ({ ...prevState, ...dados }));

    handlePhases(-1);
  }

  function handlePhases(number: number) {
    if (phases + number > 5 || phases + number < 1) {
      setPhases(phases);
    } else {
      setPhases(phases + number);
    }
  }

  const phaseStatus = useMemo(() => {
    const phaseMap: { [key: number]: string } = {
      1: `${translator('Informações gerais')}`,
      2: `${translator('Dados do Paciente')}`,
      3: `${translator('Prescrição')}`,
      4: `${translator('Assinatura do documento')}`,
    };
    return `${translator('Etapa')} ${phases} ${translator('de')} ${
      Object.entries(phaseMap).length
    }: ${phaseMap[Number(phases)]}`;
  }, [phases]);

  return (
    <Container>
      <TitleDoc>{`${translator('Atestado Médico')}`}</TitleDoc>
      <Phases>{phaseStatus}</Phases>
      <Body>
        {phases === 1 && (
          <GeneralData
            professional={props.professional}
            next={next}
            state={certificate}
            consultant={props.consultant}
          />
        )}
        {phases === 2 && (
          <PacientData
            professional={props.professional}
            next={next}
            state={certificate}
            consultant={props.consultant}
            back={() => handlePhases(-1)}
          />
        )}
        {phases === 3 && (
          <PrescriptionData
            professional={props.professional}
            next={next}
            state={certificate}
            consultant={props.consultant}
            back={() => handlePhases(-1)}
          />
        )}
        {phases === 4 && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <Signature
              changeSelected={(e: {
                signatureSource: string | undefined;
                certificateId: string;
                certificate: string;
              }) => {
                setCertificate(prevState => ({
                  certificate: e.certificate,
                  certificateId: e.certificateId,
                  signatureSource: e.signatureSource,
                  ...prevState,
                }));
              }}
              onSignSubmit={CreateMedicalCertificate}
              changeDisabled={(state: boolean) => setDisableButton(state)}
            />
          </div>
        )}
      </Body>
      {phases === 4 && (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handlePhases(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={CreateMedicalCertificate}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      )}
    </Container>
  );
};

export default ConnectComponent(MedicalCertificateSideBar);
