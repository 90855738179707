import React from 'react';

import styled from 'styled-components';
import { components } from 'react-select';
import { defaultTheme } from '~/presentation/base/themes';
import { IconArrowSelect } from '~/presentation/base/icons';

interface iGroup {
  quant?: string[];
  gap?: string;
}

interface iContainer {
  role?: 'PRO' | 'ORG' | 'CON' | 'ADM';
}

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '38px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuList: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  option: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuPortal: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
});

export const Option = ({ children: childrenOption, ...props }: any) => (
  <components.Option {...props} data-testid="option-participant">
    {childrenOption}
  </components.Option>
);

export const Control = ({ children: childrenControl, ...props }: any) => (
  <components.Control {...props} data-testid="input-participants">
    {childrenControl}
  </components.Control>
);

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconArrowSelect data-testid="icon-participants" />
    </components.DropdownIndicator>
  );
};

export const Container = styled.div<iContainer>`
  width: ${({ role }) =>
    role === 'PRO' || role === 'ORG' || role === 'ADM' ? '466px' : '100%'};
  border: 1px solid #dedede;
  overflow-y: auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  .icon {
    position: absolute;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const ContainerForm = styled.form`
  /* padding: 24px; */
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
`;

export const ContentFilters = styled.div`
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #dedede;
`;

export const InlineFilters = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const FilterData = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 8px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #dedede;
`;

export const FormItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    font-weight: normal;
    font-size: 16px;
    color: #000000;
  }
  justify-content: space-between;
  .hover-input {
    :hover {
      background: #f4f7fe;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 24px;
`;

export const ContentFiltersPatient = styled.div`
  width: 100%;
  padding: 24px;
`;

export const InlineInputs = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  white-space: nowrap;
`;

export const ContainerDocs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-style: normal;
  font-weight: normal;
  color: #7a7a7a;
  input {
    height: 16px;
    width: 16px;
    border-radius: 2px;
  }
`;

export const ClinicalDocs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-style: normal;
  font-weight: normal;
  color: #7a7a7a;
  input {
    height: 16px;
    width: 16px;
    border-radius: 2px;
  }
`;

export const FormGroup = styled.div<iGroup>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.quant ? props.quant.join(' ') : '1fr 1fr'};
  grid-gap: ${props => props.gap || '10px'};
`;

export const Options = styled.option`
  width: 100%;
  min-width: 211px;
  background-color: #ffffff;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 16px;
`;

export const FilterDataPatient = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
`;

export const ContentPatient = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  height: fit-content;
  gap: 14px;
  justify-content: flex-end;
  border: 1px solid #dedede;
`;
