import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Steps } from '@wisecare-tech/design-system-web';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteCreateOnCallRequest } from '~/main/factories/usecases/onCallRequest/CreateOnCallRequestFactory';
import { makeRemoteListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { makeRemoteUpdateOnCallRequest } from '~/main/factories/usecases/onCallRequest/UpdateOnCallRequestFactory';
import { History } from '~/main/routes';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import {
  fileSchema,
  requestSchema,
} from '~/validation/validators/diagnosis/CreateRequestValidator';
import { Files, Request } from '.';
import { AlertMessage } from '../messages/AlertMessage';
import {
  ButtonsContainerPrimary,
  ButtonsContainerSecondary,
  Footer,
  FormContent,
  StepContent,
} from './styles/StyledCreateRequest';

interface StepsProps {
  label: string;
  completed: boolean;
  active: boolean;
}

export type FormData = {
  state: string;
  county: string;
  healthUnit: string;
  responsePriority: string;
  description: string;
  modality: string;
  profession: string;
  specialty: string;
  profissional: string;
  fullName: string;
  isDisabled: boolean;
  sex: string;
  birthDate: string;
  regType: string;
  regValue: string;
  motherName: string;
  phone: string;
  patientDescription: string;
  files: Array<
    { id: number; filename: string; size?: number } & {
      examType?: number;
      loading?: boolean;
    }
  >;
  specialtySelected: boolean;
  professionalSelected: boolean;
  consultant: number;
  orgUnit?: number;
  isEditing?: boolean;
  isLoading?: boolean;
};

const schemas = [requestSchema, fileSchema];

const CreateRequest = () => {
  const [crement, setCrement] = useState<number>(1);
  const [healthUnitInfo, setHealthUnitInfo] = useState<{
    cnes?: string;
    name?: string;
  }>({});
  const [steps, setSteps] = useState<StepsProps[]>([
    {
      label: 'Informações gerais',
      active: true,
      completed: false,
    },
    {
      label: 'Arquivos',
      active: false,
      completed: false,
    },
  ]);
  const professional = getProfessionalInfo();

  const stateParams = useLocation<{ id: number }>().state;

  const methods = useForm<FormData>({
    defaultValues: {
      description: '',
      state: 'PB',
      county: '',
      healthUnit: '',
      modality: '',
      responsePriority: '',
      specialtySelected: true,
      professionalSelected: false,
      isLoading: false,
      isDisabled: false,
      profession: '',
      specialty: '',
      profissional: '',
      birthDate: '',
      regType: '',
      regValue: '',
      fullName: '',
      motherName: '',
      phone: '',
      files: [],
      sex: '',
    },
    resolver: zodResolver(schemas[crement - 1]) as any,
    mode: 'onSubmit',
  });

  const { records: healthUnitRecords } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const {
    handleSubmit,
    watch,
    errors,
    formState: { isValid },
  } = methods;

  const onSubmit: SubmitHandler<FormData> = data => {
    nextStep();
  };

  const isValidFile = useCallback(() => {
    if (watch('files').length)
      return !watch('files').some(el => el.examType === undefined);

    return true;
  }, [watch('files')]);

  const nextStep = () => {
    const currentStep = steps?.findIndex(step => step.active);

    if (currentStep === steps?.length - 1) return;

    const updatedSteps = steps.map((step, index) => {
      if (index === currentStep) {
        return { ...step, active: false, completed: true };
      }
      if (index === currentStep + 1) {
        return { ...step, active: true, completed: false };
      }
      return step;
    });

    setSteps(updatedSteps);
    setCrement(prev => prev + 1);
  };

  const backStep = () => {
    const currentStep = steps?.findIndex(step => step.active);

    if (currentStep === 0) return;

    const updatedSteps = steps.map((step, index) => {
      if (index === currentStep) {
        return { ...step, active: false, completed: false };
      }
      if (index === currentStep - 1) {
        return { ...step, active: true, completed: false };
      }
      return step;
    });

    setSteps(updatedSteps);
    setCrement(prev => prev - 1);
  };

  const createDiagnosisReport = () => {
    const parsedDate = parse(watch('birthDate'), 'dd/MM/yyyy', new Date());

    const formattedDate = format(parsedDate, 'yyyy-MM-dd');

    const sendFiles = watch('files').map(file => {
      return {
        id: String(file?.id),
        examType: file?.examType ?? -1,
      };
    });

    makeRemoteCreateOnCallRequest()
      .create({
        onCall: watch('specialty') ? Number(watch('specialty')) : -1,
        requester: professional?.id ?? -1,
        healthUnit: Number(watch('healthUnit')),
        priority: watch('responsePriority') as 'LOW' | 'NORMAL' | 'EMERGENCY',
        description: watch('description'),
        consultantExternal: watch('consultant')
          ? undefined
          : {
              fullname: watch('fullName'),
              birthdate: formattedDate,
              docType: watch('regType'),
              docValue: watch('regValue').replace(/\D/g, ''),
              mothername: watch('motherName'),
              phone: watch('phone').replace(/\D/g, ''),
              sex: watch('sex'),
            },
        consultant: watch('consultant') ?? undefined,
        files: sendFiles ?? undefined,
      })
      .then(() => {
        makeReduxActiveMessage().active({
          active: 'requestSuccess',
          data: {
            professionalId: professional?.id ?? undefined,
            profession: watch('profession'),
            specialty: watch('specialty'),
            priority: watch('responsePriority'),
          },
          actionOk: () => {
            History.getHistory().goBack();
            methods.reset();
            closeModal();
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao criar interconsulta',
          type: 'danger',
        });
      });
  };

  const editDiagnosisReport = () => {
    const parsedDate = parse(watch('birthDate'), 'dd/MM/yyyy', new Date());

    const formattedDate = format(parsedDate, 'yyyy-MM-dd');

    const sendFiles = watch('files').map(file => {
      return {
        id: String(file?.id),
        examType: file?.examType ?? -1,
      };
    });

    if (stateParams?.id && watch('isEditing'))
      makeRemoteUpdateOnCallRequest()
        .update({
          requestId: Number(stateParams?.id),
          body: {
            healthUnit: Number(watch('healthUnit')),
            priority: watch('responsePriority') as
              | 'LOW'
              | 'NORMAL'
              | 'EMERGENCY',
            description: watch('description'),
            oncall: Number(watch('specialty')),
            consultant: watch('consultant') ?? undefined,
            consultantExternal: watch('consultant')
              ? undefined
              : {
                  fullname: watch('fullName'),
                  birthdate: formattedDate,
                  docType: watch('regType'),
                  docValue: watch('regValue').replace(/\D/g, ''),
                  mothername: watch('motherName'),
                  phone: watch('phone').replace(/\D/g, ''),
                  sex: watch('sex'),
                },
            files: sendFiles ?? undefined,
          },
        })
        .then(() => {
          AlertMessage({
            message: 'Solicitação atualizada com sucesso!',
            type: 'success',
          });

          History.getHistory().goBack();
          methods.reset();
        })
        .catch(() => {
          AlertMessage({
            message: 'Falha ao atualizar a solicitação!',
            type: 'danger',
          });

          closeModal();
        });
  };

  useEffect(() => {
    if (stateParams?.id) {
      makeRemoteListOnCallRequest()
        .list({
          body: {
            filters: {
              id: Number(stateParams?.id),
            },
          },
        })
        .then(res => {
          const data = res.records[0];
          const birthdate = format(
            new Date(data.consultant.birthdate),
            'dd/MM/yyyy',
          );

          methods.register('isDisabled');
          methods.register('files');
          methods.register('isEditing');
          methods.setValue('isDisabled', true);
          methods.setValue('isEditing', true);
          methods.setValue('description', data.description);
          methods.setValue('county', data.healthUnit.city.codeFull);
          methods.setValue('healthUnit', String(data.healthUnit.id));
          methods.setValue('responsePriority', data.priority);
          methods.setValue('profession', data.specialty.id); // to check
          methods.setValue('specialty', String(data.onCall.id));
          methods.setValue('profissional', data.requester);
          methods.setValue('birthDate', birthdate);
          methods.setValue('regType', data.consultant.docType);
          methods.setValue('regValue', data.consultant.docValue);
          methods.setValue('fullName', data.consultant.fullname);
          methods.setValue(
            'files',
            data.files.map(item => ({ ...item, id: String(item.id) })),
          );
          methods.setValue(
            'sex',
            data.consultant.sex === 'M' ? 'MALE' : 'FEMALE',
          );
        })
        .catch(() => {
          console.log('error');
        });
    }
  }, [stateParams?.id]);

  useEffect(() => {
    if (watch('healthUnit')) {
      const healthUnit = healthUnitRecords.find(
        item => item.id === Number(watch('healthUnit')),
      );

      setHealthUnitInfo({
        cnes: healthUnit?.cnes,
        name: healthUnit?.name,
      });
    }
  }, [watch('healthUnit')]);

  return (
    <FormProvider {...methods}>
      <form
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <FormContent>
          <Steps steps={steps} />
        </FormContent>
        <StepContent>
          {crement === 1 && <Request />}
          {crement === 2 && <Files />}
        </StepContent>
        <Footer>
          <ButtonsContainerSecondary>
            <Button
              variant="outlinedDestructive"
              type="button"
              size="md"
              onClick={() => {
                methods.reset();
                History.getHistory().goBack();
                closeModal();
              }}
            >
              Cancelar
            </Button>
          </ButtonsContainerSecondary>

          <ButtonsContainerPrimary>
            {crement > 1 && (
              <Button
                variant="outlined"
                size="md"
                onClick={e => {
                  e.preventDefault();
                  backStep();
                }}
              >
                Voltar
              </Button>
            )}
            <Button
              variant="primary"
              size="md"
              type="submit"
              disabled={(crement === 2 && !isValidFile()) || watch('isLoading')}
              onClick={e => {
                if (crement === 2) {
                  if (watch('isEditing')) {
                    editDiagnosisReport();
                  } else {
                    createDiagnosisReport();
                  }
                }
                e.preventDefault();
                handleSubmit(onSubmit)();
              }}
            >
              {crement === 2 ? 'Concluir' : 'Avançar'}
            </Button>
          </ButtonsContainerPrimary>
        </Footer>
      </form>
    </FormProvider>
  );
};

export default CreateRequest;
