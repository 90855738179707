import { ColumnDef } from '@tanstack/react-table';
import {
  IconButton,
  SortButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { IconFlag } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { priorityMap } from '~/utils/mapPriority';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import { Information, Priority } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeRemoteCancelOnCallRequest } from '~/main/factories/usecases/onCallRequest/CancelOnCallRequestFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { History } from '~/main/routes';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { iOnCallRequestRecords } from '~/domain/usecases/onCallRequest/remote';

export const columns_waiting: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as iOnCallRequest['records'][0]['consultant'];

      const rowOriginal = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(data?.birthdate)}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'onCall',
    header: ({ column }) => {
      return <SortButton column={column}>Especialidade</SortButton>;
    },
    cell: ({ row }) => {
      const onCall = row.getValue(
        'onCall',
      ) as iOnCallRequest['records'][0]['onCall'];

      return <Typography variant="b4_14regular">{onCall?.name}</Typography>;
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <SortButton column={column}>Prioridade</SortButton>,
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as iOnCallRequest['records'][0]['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    accessorKey: 'created',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const created = row.getValue(
        'created',
      ) as iOnCallRequest['records'][0]['created'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(created)?.date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(created)?.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      const professional = getProfessionalInfo();

      const cancelInterConsult = () => {
        if (!professional) return;

        makeRemoteCancelOnCallRequest()
          .cancel({
            onCallId: value?.onCall?.id,
            requestId: value?.id,
          })
          .then(() => {
            AlertMessage({
              title: 'Solicitação cancelada',
              message: 'A solicitação foi cancelada com sucesso.',
              type: 'success',
            });
          })
          .catch(() => {
            AlertMessage({
              title: 'Erro ao cancelar solicitação',
              message:
                'Ocorreu um erro ao cancelar a solicitação, tente novamente.',
              type: 'danger',
            });
          });
      };

      const store: iStore = storeDev.getState();
      const { role } = store.auth.selectUser;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Tooltip content="Editar" arrow>
            <IconButton
              icon="edit"
              variant="primary"
              size="sm"
              style={{
                visibility: row.original?.permissions?.edit
                  ? 'visible'
                  : 'hidden',
              }}
              onClick={() => {
                History.getHistory().push('/request/create', {
                  id: value?.id,
                  info: {
                    city: {
                      id: value?.healthUnit?.city?.codeFull,
                      name: value?.healthUnit?.city?.name,
                    },
                    healthUnitId: value?.healthUnit?.id,
                    healthUnitName: value?.healthUnit?.name,
                  },
                });
              }}
            />
          </Tooltip>
          <Tooltip content="Cancelar" arrow>
            <IconButton
              icon="cancel"
              variant="destructive"
              size="sm"
              style={{
                visibility: row.original?.permissions?.cancel
                  ? 'visible'
                  : 'hidden',
              }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  data: {
                    variant: 'CANCEL',
                    message:
                      'Você está cancelando esse pedido de interconsulta. Ela será movida para “finalizados”, você poderá reenviar essa solicitação caso precisar. Tem certeza de que deseja continuar?',
                    textSecondaryButton: 'Voltar',
                    textPrimaryButton: 'Sim, quero cancelar',
                    title: 'Cancelar solicitação',
                  },
                  actionOk: () => {
                    cancelInterConsult();
                  },
                });
              }}
            />
          </Tooltip>
        </div>
      );
    },
  },
];
