import { theme } from '@wisecare-tech/design-system-web';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  overflow-y: auto;
`;

interface iDropzone {
  status?: 'success' | 'error' | 'default';
}

export const Dropzone = styled.div<iDropzone>`
  width: 100%;
  max-width: 886px;
  padding: 16px 24px;
  border: ${({ status }) => {
    switch (status) {
      case 'success':
        return '2px dashed #00b341';
      case 'error':
        return '2px dashed #f00';
      default:
        return '2px dashed #B4B7B9';
    }
  }};

  border-radius: 8px;
  background-color: #ffffff;
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  span {
    font-size: 16px;
    color: #0565d9;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileSize = styled.div`
  display: flex;
  gap: 8px;

  p {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #656a6e;
  }

  strong {
    color: #656a6e;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const FilesFormats = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #656a6e;
  }

  strong {
    color: #656a6e;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const TableContainer = styled.div`
  border: 1px solid #dedede;
  border-radius: 8px;
  @media (max-width: 1366px) {
    max-height: 260px;
  }

  max-height: 426px;
  max-width: 886px;
`;

export const DeleteFiles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
`;

export const Loader = styled.span`
  width: 32px;
  height: 32px;
  border: 5px solid ${theme.colors.blue[7]};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
