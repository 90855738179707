import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { RemoteGetClinicalDocs } from '~/data/repository/clinicalDocs';
import { iStore } from '~/domain/interfaces/models';
import { ListOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
import NewReport from '~/presentation/pages/NewReport';
import { makeRemoteGetClinicalDocs } from '../../usecases/clinicalDocs/GetClinicalDocsFactory';

const MakeNewReportFactory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [opinion, setOpinion] = useState('');
  const [data, setData] = useState<
    ListOnCallRequest.Model['records'][0] | undefined
  >(undefined);

  let { id, specialty } = useParams<{ id: string; specialty: string }>();
  let edit = useLocation<{ edit: boolean }>().state?.edit;
  const { records } = useSelector((store: iStore) => store.onCallRequest);

  useEffect(() => {
    console.log('>>> edit', edit);
    const data_ = records.find(item => Number(item.id) === Number(id));
    setData(data_);

    if (edit && data_?.currentAppointment?.medicalOpinion) {
      makeRemoteGetClinicalDocs()
        .get({
          docId: Number(data_?.currentAppointment?.medicalOpinion),
        })
        .then(res => {
          console.log('>>> res', res);
          setOpinion(res.content.opinion);
        })
        .catch(error => {
          console.log('>>> error', error);
        });
    }
  }, [records, edit]);

  return <NewReport data={data} edit={edit} opinion={opinion} />;
};

export default MakeNewReportFactory;
