import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 886px;
  width: 100%;
`;

export const InlineForms = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 2px;
    font-family: 'Roboto', sans-serif;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #b4b7b9;
    background: #fdfdfd;
    padding: 12px;
    outline: 0;

    ::placeholder {
      color: #818385;
    }
  }

  #fullName {
    width: 600px;
  }
`;

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuList: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  option: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuPortal: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
});

export const Required = styled.div`
  color: #ea3e4f;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 8px;
`;

interface iSelectLabel {
  required?: boolean;
}
export const SelectLabel = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;
